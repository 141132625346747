
// const api_id ="{$api_id}"
// const api_key="{$api_key}"
// const filename="{$filename}"
// const url="{$url}"
// EverAPI 配置
import {everapi} from "../../../assets/js/everapi";

const config = {}

// api 是否已经加载成够的标志位
let apiLoaded = false

// 新建 api 实例
// 构造函数的前两个参数分别是 AppID 和 AppKey，这里的参数可用于本地 localhost:9000 调试
// 
// const api = new EverAPI(api_id, api_key, config)

// // api 加载成功后调用 loadApi 函数
// window.addEventListener('oneverapiloaded', () => {
//     // 此处放需要渲染的文件
//     const files = [{ filename: "{$filename}", url: "{$url}" }]https://api.evercraf
//     loadApi(files)
// })
// 此函数中调用 api 的 render 方法，在网页中加载出 api 窗口

function getQueryString(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
    var r = window.location.search.substr(1).match(reg);
    if (r != null) return unescape(r[2]); return null;
}

export default {
    data() {
        return {
            imgShow:false
        }
    },
    methods: {
        imgclose:function(){
            this.imgShow = false
        },
        everCraft: function (tzurl, name) {
            if(!tzurl || !name){
                this.$message('此格式不支持3D预览');
                return;
            }
            this.imgShow = true
            const config ={}
            let apiLoaded = false
            const api = new everapi('c4a0ab6479f5', '80761c29e3a30c1ad006fe8cf97f6884', config)
            // api 加载成功后调用 loadApi 函数
            window.addEventListener('oneverapiloaded', () => {
                // 此处放需要渲染的文件
                const files = [{ filename: name, url: tzurl }]
                loadApi(files)
            })
            function loadApi (files) {
                // 此方法的第一个参数为放置 dom 元素的 id，第二个参数为需要在 api 中显示的模型的数组
                api.render('root', files)
                apiLoaded = true
            }
        }
    }
}