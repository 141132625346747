export default{
    data: function () {
        return {
            tHeader:[
                {
                    text:'选择',
                    id:1
                },{
                    text:'序号',
                    id:2
                }, {
                    text:'3D预览',
                    id:3
                }, {
                    text:'图纸编号',
                    id:3
                },{
                    text:'零件名称',
                    id:4
                },{
                    text:'零件格式',
                    id:5
                },{
                    text:'附件',
                    id:6
                },{
                    text:'版本号',
                    id:7
                },{
                    text:'文件大小',
                    id:8   
                },{
                    text:'数量',
                    id:9
                },{
                    text:'备注',
                    id:10
                }
            ],
            contact_user:'',
            phone_num:'',
            all:true,
            content:'',
            show:false
        }
    },
    created(){
        if(this.$store.state.isLogin == 0){
            this.$alert('欢迎来到矜群科技', '提示', {
                 confirmButtonText: '确定',
                 callback: action => {
                        this.$router.replace('/login')
                 }
               });
          }
       this.setData()
    },
    watch:{
        xy(val){
          if(val == 2){
              this.show = true;
            this.getServiceTerms();
          }
        }
    },
    methods: {
        getServiceTerms:function(){
            this.$post('/api/index/serviceTerms.html', {}).then((res) => {
                console.log(res)
                if(res.code == 200){
                    console.log(res.data.servic_list.content);
                    this.content = res.data.servic_list.content[3].content || '';
                }else{
                    return this.$message(res.msg); 
                }
            }).finally(() => {
        
            })
        },
        close:function(){
            this.show = false;
        },
        setData: function () {
            console.log(this.$store.state.userInfo)
            this.phone_num = this.$store.state.userInfo.mobile
            if(this.$store.state.userInfo.user_type == 1){
                this.contact_user =  this.$store.state.userInfo.username || this.$store.state.userInfo.name  
            }else{
                this.contact_user = this.$store.state.userInfo.enterprise_name || this.$store.state.userInfo.username || this.$store.state.userInfo.name  
            }
        },
        getServiceList: function () {
            this.$post('/api/index/serviceList.html', {}).then((res) => {
                console.log(res)
                if (res.code == 200) {
                    this.serviceList  = res.data || []      
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
        checkedItem:function(index){
            console.log(index)
            let productList = this.productList;
            if(productList[index].checked == 1){
                this.productList[index].checked = 0;
            }else{
                this.productList[index].checked = 1;
            }
            for(let i  = 0;i<this.productList.length;i++){
               if(this.productList[i].checked == 0){
                this.all = false
               }else{
                this.all = true 
               }
            }
        },
        checkedAll:function(){
        
            if(this.all){
                for(let i  = 0;i<this.productList.length;i++){
                    this.productList[i].checked = 0;
                }
                this.all = false
            }else{
                for(let i  = 0;i<this.productList.length;i++){
                    this.productList[i].checked = 1;
                }
                this.all = true
            }
          
        },
        change:function(e){
            let value = e.currentTarget.value;
            let key = e.target.dataset.key;
            let oldname = e.target.dataset.oldname;
            let productList = this.productList;
            for(let i = 0;i<productList.length;i++){
                if(productList[i].img_name == oldname){
                    console.log(i)
                    productList[i][key] = value;
                }
            }
            this.productList = productList
        }, 
        saveOrder:function(){
            let productList = this.productList;
            let data = [];
           for(let i = 0;i<productList.length;i++){
            if(productList[i].checked == 1){
                data.push(productList[i])
            }
           }
            let fromData ={
                order_id: this.$route.params.id || '',
                category_id:this.value,
                contact_user:this.contact_user,
                phone_num:this.phone_num,
                token:true,
                is_secrecy:this.xy,
                data:JSON.stringify(data)
            }
            this.$post('/api/order/saveOrder.html', fromData).then((res) => {
                console.log(res)
                if (res.code == 200) {
                  /* this.$message('上传已完成，我们会立刻安排报价，请耐心等待。');
*/
                   this.$message({
                       type:'success',
                       offset:'100',
                       center:'center',
                       message:'上传已完成，我们会立刻安排报价，请耐心等待。'
                   })

                    this.$router.replace('/user-center/order')   
                }else{
                    return this.$message(res.msg);
                }
            }).finally(() => {

            })
        },
    },
}